import React from 'react';
import { join } from 'path';
import { client } from 'graphql-sdk';
import dynamic from 'next/dynamic';

const preview = process.env.CONTENTFUL_USE_PREVIEW === 'true';
const site = process.env.SITE;
const pagesRevalidate = parseInt(process.env.PAGES_REVALIDATE as string, 10) ?? 3600;
const revalidate = !isNaN(pagesRevalidate) ? pagesRevalidate : false;
const AppProvider = dynamic(() => import('@ui/AppProvider/AppProvider'));
const ContentModule = dynamic(() => import('@ui/ContentModule'));
const SEO = dynamic(() => import('@ui/SEO'));

export type PageGetStaticPathsProps = {
  locales: string[];
};

const STATIC_PATHS = [
  'whats-eligible',
  'compare-health-insurance-plans',
  'payroll-tax-savings',
  'careers/',
  'search'
];

export const getStaticPaths = async ({ locales }: PageGetStaticPathsProps) => {
  try {
    return {
      paths:
        (await client.Paths({ locales, preview, site }))?.data?.paths?.filter(
          (p) => !STATIC_PATHS?.includes(p.params.slug?.join('/')!)
        ) ?? [],
      fallback: revalidate ? 'blocking' : false
    };
  } catch (error) {
    return {
      paths: [],
      fallback: false
    };
  }
};

export type PageStaticPropsProps = {
  params: {
    slug?: string[];
  };
  locale: string;
};

export const getStaticProps = async ({ params, locale }: PageStaticPropsProps) => {
  try {
    const path = join('/', (params.slug ?? ['/']).join('/'));

    if (STATIC_PATHS.includes(path)) {
      return false;
    }
    const { data: pageData } = await client.Page({ path, locale, preview, site });
    if (!pageData?.page) {
      return {
        notFound: true,
        revalidate
      };
    }
    return {
      props: {
        pageData
      },
      revalidate
    };
  } catch (err: any) {
    // if (err.name == 'FetchError') {
    //   console.log('[Error][GetStaticProps]', err);
    // } else {
    //   console.log(err);
    // }
    console.log(err);
    throw err;
  }
};

export default function Page({ pageData, ...other }: any) {
  return (
    <AppProvider>
      {pageData?.page?.seo ? <SEO page={pageData.page} /> : null}
      <ContentModule {...pageData?.page} />
    </AppProvider>
  );
}
